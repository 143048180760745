import * as React from "react"
import '../neat-basics/index.scss'
import "./theming.scss"
import "../styles/styles.scss"
import SubPageHeader from "../modules/sub-page-header"
import Page from "../modules/page"
import Section from "../modules/section";
import Teaser, {Type} from "../modules/teaser";
import Navigation from "../modules/navigation";
import Footer from "../modules/footer";
import {graphql} from "gatsby";
import {ArticleQueryResult} from "../types";
import MobileNavigation from "../modules/mobile-navigation";
import Main from "../modules/main";

const Research = (page: ArticleQueryResult) => {
    return (<>
            <Main title="Research">
                <Page>
                    <SubPageHeader title="Research" intro="">
                        <Navigation activePage="research"/>
                        <MobileNavigation activePage="research"/>
                    </SubPageHeader>
                    <Section>
                        {page.data.articles.nodes.map(article => (
                            <Teaser image={article.frontmatter.image} type={Type.LARGE} date={article.frontmatter.date}
                                    title={article.frontmatter.title} text={article.excerpt}
                                    actionPath={article.fields.slug} defaultImage={true}/>)
                        )}
                    </Section>
                    <Footer/>
                </Page>
            </Main></>
    )
}

export default Research

export const pageQuery = graphql`
  query ResearchArticles {
    articles: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "research_article"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        html
        excerpt(format: PLAIN, pruneLength: 5000)
        fields {
          slug
        }
        frontmatter {
          date
          title
          image {
           childImageSharp {
             gatsbyImageData(width: 1500)
           }
        }
        }
      }
    }
  }
`
